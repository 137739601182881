import { endpoints } from 'api/endpoints';
import { IconApplication } from 'assets/icons';
import type { AxiosError } from 'axios';
import { ChatOrder } from 'components/ChatOrder';
import { ApplicationType } from 'enums/applicationType';
import { ChatEntityType } from 'enums/chatEntityType';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFetchData } from 'hooks/useFetchData';
import useMediaQuery from 'hooks/useMediaQuery';
import { NoneOrders } from 'pages/Orders/NoneOrders';
import { OrderTypesEnum } from 'pages/Orders/enums';
import { useNavigate, useParams } from 'react-router-dom';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';
import { NearbyRequests } from 'services/OrderService/types';
import { Order } from 'types';
import { Spinner } from 'ui/Spinner';
import TabGroup from 'ui/TabGroup';
import { OrderCardMobile } from 'ui/redesign/OrderCard';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { Table } from 'ui/redesign/Table';
import { TableCard } from 'ui/redesign/Table/TableCard';
import { fetchFile, handleFileDownload } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { OrderDetailsSkeleton } from './OrderDetailsSkeleton';
import { OrderDetailsTab } from './components/OrderDetailsTab';
import CompletedWork from './components/OrderTab/CompletedWork';
import { nearbyRequestsTableHeadings } from './constants';

const OrderDetailsPage = () => {
  const { type = OrderTypesEnum.new } = useParams<{ type: OrderTypesEnum }>();
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const userProfile = useAppSelector(getProfile);
  const executorType = useAppSelector(getExecutorType);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const filteredNearbyRequestsTablTableHeadings =
    executorType === ExecutorTypeEnum.DRITS
      ? nearbyRequestsTableHeadings.filter((item) => item.name !== 'Стоимость')
      : nearbyRequestsTableHeadings;

  const {
    data: order,
    isLoading,
    refetch,
  } = useFetchData<Order>(
    endpoints.GET_ORDER_DETAILS.url(orderId),
    endpoints.GET_ORDER_DETAILS.type,
    {},
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 403) {
          navigate(-1);
        }
      },
    }
  );

  const { data: nearbyRequests, isLoading: nearbyRequestsIsLoading } = useFetchData<NearbyRequests>(
    endpoints.NEARBY_REQUESTS.url(orderId, orderId, 1, 100),
    endpoints.NEARBY_REQUESTS.type
  );

  const tabItems = [
    {
      title: `${isSmall ? `Заявка № ${order?.customId}` : 'Данные по заявке'}`,
      permissions: true,
    },
    {
      title: `Выполнение`,
      permissions: true,
    },
    { title: `Рядом `, counter: nearbyRequests?.totalCount, permissions: true },
  ];

  const subTabItems = [
    {
      title: `${isSmall ? `Заявка № ${order?.customId}` : 'Данные по заявке'}`,
      permissions: true,
    },
    {
      title: `Выполнение`,
      permissions: true,
    },
  ];

  const handleRowClick = (tableRow: TableData): void => {
    navigate(`/orders/${type}/${tableRow.id}`);
  };

  const tabContent = [
    order && (
      <OrderDetailsTab
        order={order}
        refetch={refetch}
        isLoading={nearbyRequestsIsLoading}
        key={1}
      />
    ),
    order?.worksInfo && (
      <CompletedWork
        worksInfo={order.worksInfo}
        onDownload={(fileHash) => handleFileDownload(fetchFile(fileHash))}
      />
    ),
    nearbyRequests?.pagedList.length ? (
      <>
        {isSmall ? (
          <>
            {nearbyRequests?.pagedList &&
              nearbyRequests?.pagedList
                .sort((a, b) => a.distanceInMeters - b.distanceInMeters)
                .map((order: any) => (
                  <OrderCardMobile
                    key={order.id}
                    order={{
                      totalCost: 0,
                      clientId: order?.clientId ? order?.clientId : 0,
                      newChatMessages: false,
                      clientName: order.clientShortName,
                      estimatedCost: order.costForSelfEmployed,
                      status: 1,
                      cityName: order.address,
                      ...order,
                    }}
                  />
                ))}
          </>
        ) : (
          <TableCard>
            <div className="mt-4">
              <Table
                tableHeading={filteredNearbyRequestsTablTableHeadings}
                tableData={
                  (nearbyRequests?.pagedList.sort(
                    (a, b) => a.distanceInMeters - b.distanceInMeters
                  ) || []) as unknown as TableData[]
                }
                onRowClick={handleRowClick}
              />
            </div>
          </TableCard>
        )}
      </>
    ) : nearbyRequestsIsLoading ? (
      <Spinner />
    ) : (
      <div className="mt-4 max-w-[550px] sm:p-4">
        <NoneOrders icon={<IconApplication />} heading="Заявок рядом нет" description="" />
      </div>
    ),
  ];

  if (isLoading) return <OrderDetailsSkeleton />;

  return (
    <PageWrapper title={order?.name || `Заявка №${order?.customId}`}>
      <section className="flex flex-col">
        <div className="flex flex-col">
          {userProfile.executorType === 4 ? (
            <TabGroup
              tabItems={
                order?.worksInfo?.description || order?.worksInfo?.workActFiles?.length
                  ? subTabItems
                  : subTabItems.slice(0, 1)
              }
              tabPanelsContent={
                order?.worksInfo?.description || order?.worksInfo?.workActFiles?.length
                  ? tabContent
                  : tabContent.slice(0, 1)
              }
            />
          ) : (
            <TabGroup
              tabItems={
                type === OrderTypesEnum.new
                  ? tabItems.slice(0, 1).concat(tabItems.slice(2))
                  : order?.worksInfo?.description || order?.worksInfo?.workActFiles?.length
                  ? tabItems
                  : tabItems
                      .slice(0, 1)
                      .concat(nearbyRequests?.pagedList.length ? tabItems.slice(2) : [])
              }
              tabPanelsContent={
                type === OrderTypesEnum.new
                  ? tabContent.slice(0, 1).concat(tabContent.slice(2))
                  : order?.worksInfo?.description || order?.worksInfo?.workActFiles?.length
                  ? tabContent
                  : tabContent
                      .slice(0, 1)
                      .concat(nearbyRequests?.pagedList.length ? tabContent.slice(2) : [])
              }
            />
          )}
        </div>
        {orderId && order?.status !== 1 && order?.status !== 4 && (
          <div className="fixed right-0 bottom-[50px] z-0 mr-2 flex w-full max-w-[375px] justify-end">
            <ChatOrder
              orderId={+orderId}
              customId={order?.customId}
              applicationID={ApplicationType.Outsourcing}
              entityType={ChatEntityType.OrderChat}
            />
          </div>
        )}
      </section>
    </PageWrapper>
  );
};
export default OrderDetailsPage;
