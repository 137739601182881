import { OrderFile } from './index';
import { OptionType } from 'ui/Select';

export const Statuses: {
  [id: number]: { id: number; name: string; color: string; label?: string };
} = {
  1: {
    id: 1,
    name: 'Исполнитель не назначен',
    color: 'bg-blue',
  },
  2: {
    id: 2,
    name: 'В работе диспетчера',
    color: 'bg-primary-60',
  },
  3: {
    id: 3,
    name: 'В работе',
    color: 'bg-primary-60',
  },
  4: {
    id: 4,
    name: 'От заявки отказались',
    color: 'bg-blue',
  },
  5: {
    id: 5,
    name: 'На проверке',
    color: 'bg-warning',
  },
  6: {
    id: 6,
    name: 'Выполнена',
    color: 'bg-success-60',
  },
  7: {
    id: 7,
    name: 'Отменена',
    color: 'bg-danger',
  },
  8: {
    id: 8,
    name: 'Отменена с выплатой',
    color: 'bg-danger',
  },
  9: {
    id: 9,
    name: 'Ожидает подтверждения',
    color: 'bg-warning',
  },
  10: {
    id: 9,
    name: 'Подтверждена заказчиком',
    color: 'bg-success-60',
  },
  11: {
    id: 9,
    name: 'В ожидании',
    color: 'bg-warning',
  },
};

type StatusTypes = 'new' | 'inProgress' | 'completed' | 'review';

export const StatusByType: { [p in StatusTypes]: number[] } = {
  new: [1, 4],
  inProgress: [3, 4, 9],
  completed: [6, 7, 8],
  review: [5],
};

export const StatusForOrderFilters: {
  [p: string]: { title: string; statuses: number[]; tabName: string };
} = {
  new: {
    title: 'Новые',
    statuses: StatusByType['new'],
    tabName: 'Нераспределённые заявки',
  },
  inProgress: {
    title: 'В работе',
    statuses: StatusByType['inProgress'],
    tabName: 'Заявки в работе',
  },
  completed: {
    title: 'Завершенные',
    statuses: [...StatusByType['completed'], ...StatusByType['review']],
    tabName: 'Выполненные заявки',
  },
};

export type FinalStatusFormType = {
  description: string;
  additionalFiles: OrderFile[];
  orderWorkActFiles: OrderFile[];
};

export interface ApproveItemForm {
  nomenclature: OptionType | null;
  quantity: number;
  amountUsed?: number;
}

export interface CreateItemForm {
  nomenclature: string;
  quantity: number;
}

/**
 * @see OrderActionType, Statuses
 */
export const actionToStatusMap: { [p: number]: number } = {
  12: 6,
  13: 7,
  14: 4,
};
