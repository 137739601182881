import classNames from 'classnames';
import { OrderStatusesEnum } from 'enums/orderStatusesEnum';
import { FC } from 'react';
import { Statuses } from 'types/status';

export const OrderStatusForTable: FC<{ status: OrderStatusesEnum }> = ({ status }) => {
  return (
    <span
      className={classNames(
        'block w-max rounded-[4px] px-2 py-[1px] text-sm text-white',
        Statuses[status]?.color
      )}
    >
      {Statuses[status]?.label ? Statuses[status].label : Statuses[status]?.name}
    </span>
  );
};
